import Icon from '@/components/icons/Icon';
import { isMobile, ratingText, roundRating } from '@/helpers';
import { __, _s } from '@/locale';
import { MouseEvent } from 'react';

const scrollToReviews = (e: MouseEvent<HTMLDivElement>) => {
  e.preventDefault();
  const target = document ? document.getElementById('reviews') : false;
  if (target) {
    target.scrollIntoView(true);
    // now account for fixed header
    const scrolledY = window.scrollY;
    if (scrolledY) {
      if (isMobile()) {
        window.scroll(0, scrolledY - 124);
      } else {
        window.scroll(0, scrolledY - 125);
      }
    }
  }
  return false;
};

const getStars = (rating: number, starSize?: 'sm' | 'base') => {
  let empty = false;
  return [1, 2, 3, 4, 5].map((i, key) => {
    let fill = 100;
    if (i > rating) {
      fill = !empty ? (rating % 1) * 100 : 0;
      empty = true;
    }
    return (
      <div key={key} className={`${starSizeStyle[starSize]} relative`}>
        <Icon variant="star-filled" color="black-200" style={{ width: 20, height: 20 }} />
        <Icon
          className="absolute inset-0"
          variant="star-filled"
          color="warning-500"
          style={{ width: 20, height: 20, clipPath: `inset(0 ${100 - fill}% 0 0)` }}
        />
      </div>
    );
  });
};

type RatingProps = {
  rating?: number;
  count?: number;
  justCount?: boolean;
  details?: boolean;
  showRatingText?: boolean;
  starSize?: 'sm' | 'base';
};

const starSizeStyle = {
  sm: 'h-5 w-4',
  base: 'h-5 w-5',
};

const Rating = ({
  rating = 0,
  count = 0,
  justCount = false,
  details = false,
  showRatingText = true,
  starSize = 'base',
}: RatingProps) => {
  let component = (
    <div
      className="inline-flex flex-row flex-wrap items-center justify-start"
      onClick={details ? scrollToReviews : null}
      itemProp="aggregateRating"
      itemScope
      itemType="https://schema.org/AggregateRating">
      <div className="flex cursor-pointer items-center">
        <span className="text-black-700 mr-1 overflow-hidden text-ellipsis whitespace-nowrap font-semibold">
          <meta itemProp="reviewCount" content={`${count}`} />
          <span itemProp="ratingValue">{roundRating(rating)}</span>
          {showRatingText ? <>&nbsp;{ratingText(roundRating(rating))}</> : ''}
        </span>
        {getStars(rating, starSize)}
        {count && (
          <span className="text-black-600 text-xxs before:w-xxs before:h-xxs before:mr-xs before:bg-black-600 flex items-center pl-1 before:inline-block before:rounded-full">
            {justCount ? count : count + ' ' + __('Ratings')}
          </span>
        )}
      </div>
    </div>
  );

  if (count < 5) {
    component = (
      <div onClick={details ? scrollToReviews : null}>
        <span className="">{_s('noReviews')}</span>
      </div>
    );
  }

  return component;
};

export default Rating;
