import { Component } from 'react';
import { Redirect, BrowserRouter as Router, StaticRouter } from 'react-router-dom';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.minimal.css';
import './App.scss';
import './styles/global/_actionsheet.scss';
import './styles/global/_autosuggest.scss';
import './styles/global/_base.scss';
import './styles/global/_forms.scss';
import './styles/global/_tag.scss';
import './styles/global/_tooltip.scss';
import './styles/global/_unhide.scss';
import './styles/global/_utils.scss';
import './styles/global/_wootric.scss';
import './styles/tailwind.css';
import './styles/vendors/react-toastify/main.scss';

import { userActions } from '@/actions';
import { AllowCookies } from '@/components/modules/modals';
import { connect } from 'react-redux';
import LoadingBar from 'react-top-loading-bar';
import {
  ConsentValue,
  allowTracking,
  fetchUserOnce,
  getCookie,
  getCustomerIOSiteId,
  getMainFbPixel,
  getTikTokPixel,
  hash,
  history,
  initTrackerCustomerIO,
  initTrackerFacebook,
  initTrackerTikTok,
  isSentry,
  isServer,
  isSistaminuten,
  removeCookie,
  renameCookie,
  setMobile,
  unsetMobile,
  updateConsent,
  url,
} from './helpers';

// Mobile detect
import MobileDetect from 'mobile-detect';
// Trackers
import { AlertPortal } from '@/components/modules/alert/AlertPortal';
import AppRouter from '@/components/modules/app/AppRouter';
import LoginModal from '@/components/modules/modals/LoginModal';
import * as Sentry from '@sentry/react';
import AppPromotionCard from './components/elements/Card/AppPromotionCard';
import FeaturePromo, {
  checkIfClosedPreviously,
  checkIfScreenBlocked,
  syncFeaturePromoLocalStorage,
} from './components/elements/FeaturePromo/FeaturePromo';
import UserProfileOnboarding from './components/modules/modals/UserProfileOnboarding';
import featurePromoConfig from './config/feature-promos.json';
import { Z_INDEX } from './constants/styleConstants';
import { triggerMopinionSurvey } from './helpers/survey';
import withCustomerIOInAppMessaging from './hoc/withCustomerIOInAppMessaging';
import withMobileView from './hoc/withMobileView';

if (!isServer) {
  var md = new MobileDetect(window.navigator.userAgent);
  if (md.mobile() !== null || window.innerWidth < 992) {
    sessionStorage.setItem('isMobile', true);
  } else {
    sessionStorage.setItem('isMobile', false);
  }
}

if (isServer) {
  if (typeof localStorage === 'undefined') global.localStorage = { getItem: () => false };
  if (typeof document === 'undefined') global.document = {};
}

class Application extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, visible: true, featurePromos: featurePromoConfig };

    if (props.isMobile) {
      setMobile();
    } else {
      unsetMobile();
    }

    this.checkHash();

    if (allowTracking()) {
      updateConsent(ConsentValue.Granted);
      this.initAnalytics();
      this.initFacebookPixel();
      this.initTikTokPixel();
      this.initCustomerIO();
    }

    if (!isServer) {
      let value = getCookie('LandingPage');
      if (value) {
        sessionStorage.setItem('LandingPage', value);
        removeCookie('LandingPage');
      }

      //the user has logged in, getting its profile
      const token = getCookie('BokadirektMP');
      if (token) {
        removeCookie('BokadirektMP');
        props.dispatch(userActions.profile(token, true));
        setTimeout(function () {
          history.replace({ search: url.changeGetParameter('auto-login') });
        }, 500);
      } else {
        //if it comes from edit profile
        if (window.location.hash === '#ZmV0Y2h1c2Vy') {
          props.dispatch(userActions.profile(null, null, true));
          window.location.hash = '';
        } else {
          if (fetchUserOnce()) {
            props.dispatch(userActions.profile(null, null));
          }
        }
      }
    }
  }

  initAnalytics() {
    // used for loaded ga4 salon trackers that need to be disabled until a refresh
    sessionStorage.removeItem('disabledTrk');
  }

  initFacebookPixel() {
    if (getMainFbPixel()) {
      initTrackerFacebook(getMainFbPixel());
    }
  }

  initTikTokPixel() {
    if (getTikTokPixel()) {
      initTrackerTikTok(getTikTokPixel());
    }
  }

  initCustomerIO() {
    const siteId = getCustomerIOSiteId();
    if (!siteId) return;
    initTrackerCustomerIO(siteId);
  }

  checkHash = () => {
    const { dispatch } = this.props;
    hash.check(dispatch);
  };

  componentDidMount() {
    if (!isServer) {
      window.addEventListener('pushstate', this.checkHash, false);
      window.addEventListener('hashchange', this.checkHash, false);
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'optimize.activate' });
      }
      this.setState({ ...this.state, visible: true });

      const surveyId = getCookie('clientBooker');
      triggerMopinionSurvey(surveyId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isServer && window.wootric && !document.getElementById('wootric-container')) {
      window.wootric('stop');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('pushstate', this.checkHash, false);
    window.removeEventListener('hashchange', this.checkHash, false);
  }

  render() {
    const { login, userProfileOnboarding, isMobileView, featurePromoTrigger, show } = this.props;
    let redirect = null;

    const cookieAcceptedOldName = Boolean(getCookie('CookiePreferencesMP'));
    const cookieAccepted = Boolean(getCookie('BokadirektCookiePreferencesMP'));
    const hideCookiesModal = cookieAccepted || cookieAcceptedOldName;

    if (cookieAcceptedOldName) renameCookie('CookiePreferencesMP', 'BokadirektCookiePreferencesMP');

    const showFeaturePromos = hideCookiesModal && this.state.featurePromos?.length > 0;
    syncFeaturePromoLocalStorage(featurePromoConfig);

    if (this.props.redirect) {
      redirect = <Redirect to={this.props.redirect} />;
    }

    const showLoginModal = login?.show;
    const showCompleteProfileModal = !showLoginModal && userProfileOnboarding?.show;

    const app = (
      <div className="App" style={{ visibility: this.state.visible ? 'visible' : 'hidden' }}>
        {showFeaturePromos &&
          this.state.featurePromos.map((featurePromo) => {
            if (
              !Boolean(featurePromo.id) ||
              checkIfClosedPreviously(featurePromo.id) ||
              checkIfScreenBlocked(featurePromo?.blockScreens ?? [])
            )
              return null;
            return isMobileView ? (
              <FeaturePromo
                key={`${featurePromo?.id}-mobile`}
                start={!!featurePromoTrigger[featurePromo?.id]?.triggered}
                steps={featurePromo?.['mobile'].steps}
                id={featurePromo?.id}
              />
            ) : (
              <FeaturePromo
                key={`${featurePromo?.id}-desktop`}
                start={!!featurePromoTrigger[featurePromo?.id]?.triggered}
                steps={featurePromo?.['desktop'].steps}
                id={featurePromo?.id}
              />
            );
          })}

        {!showFeaturePromos && !showLoginModal && !showCompleteProfileModal && hideCookiesModal && <AppPromotionCard />}

        {showLoginModal && <LoginModal isOpen source={login?.triggerSource} />}
        {showCompleteProfileModal && <UserProfileOnboarding isOpen />}

        <LoadingBar transitionTime={300} color={isSistaminuten() ? '#ffcb2e' : '#23A994'} progress={show ? 50 : 100} />
        <AppRouter redirect={redirect} />
        {!hideCookiesModal && <AllowCookies />}
        <AlertPortal />
        <ToastContainer
          limit={1}
          draggable={false}
          closeOnClick={false}
          position={toast.POSITION.BOTTOM_CENTER}
          autoClose={4000}
          closeButton={false}
          transition={Slide}
          style={{ zIndex: Z_INDEX.SNACKBAR }}
        />
      </div>
    );

    if (!isServer) {
      return <Router basename="/">{app}</Router>;
    } else {
      return (
        <StaticRouter history={history} context={this.props.context} location={this.props.location || '/'}>
          {app}
        </StaticRouter>
      );
    }
  }
}

function mapStateToProps(state) {
  const { show } = state.loading;
  const { user } = state.users;
  const { redirect, login, userProfileOnboarding } = state.modal;

  return {
    redirect,
    login,
    userProfileOnboarding,
    show,
    user,
    users: state.users,
    featurePromoTrigger: state.featurePromos.featurePromoTrigger,
  };
}

const App = isSentry
  ? Sentry.withProfiler(connect(mapStateToProps)(Application))
  : connect(mapStateToProps)(Application);

export default withCustomerIOInAppMessaging(withMobileView(App));
